<template>
  <div id="app">
    <Navbar ref="navbar"></Navbar>
    <router-view class="container-fluid"></router-view>
  </div>
</template>

<script>

import Navbar from "@/components/Navbar";

export default {
  name: 'App',
  components: {
    Navbar
  }
}
</script>

<style>
</style>
