import Vue from 'vue'
import Router from 'vue-router'

import Home from './components/Home'
import WGN from './components/WGN'
import CO3 from './components/CO3'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes:[
        { path: '/projects/wgn', component: WGN },
        { path: '/projects/co3', component: CO3 },
        { path: '/', name: 'home',component: Home }
    ]
})

export default router;
