<template>
  <b-navbar id="navbar" toggleable="lg" type="dark">
    <b-navbar-brand>
      <router-link to="/" ><img :src="require('../assets/images/ontomap_logo-retina-orange.png')" alt="" width="149,51" height="37"></router-link>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item>
          <router-link to="/" >Home <span class="sr-only">(current)</span></router-link>
        </b-nav-item>

        <b-nav-item-dropdown :text="$t('home.projects')" to="/projects" right>
          <router-link to="/projects/wgn" class="dropdown-item">WeGovNow</router-link>
          <router-link to="/projects/co3" class="dropdown-item">CO3</router-link>
          <b-dd-divider></b-dd-divider>
          <b-dropdown-item href="https://ontomap.ontomap.eu">Mappe Comunità di 3.0</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item href="#" @click="changeLang('it')">
          <country-flag country='it' size='small'/> ITA
        </b-nav-item>
        <b-nav-item href="#" @click="changeLang('en')">
          <country-flag country='gb' size='small'/> ENG
        </b-nav-item>
      </b-navbar-nav>

    </b-collapse>
  </b-navbar>
</template>


<script>


export default {
  name: "Navbar",
  methods: {
    changeLang (l) {
      this.$i18n.locale = l
    }
  }
}
</script>

<style scoped>

#navbar {
  background-color: rgb(28, 27, 80);
}

.nav-item{
  padding: .5rem 1rem;
  font-weight: 800;
}


a {
  color: rgba(255, 255, 255, 0.5);
}

.dropdown-item{
  color: #212529 !important;
}

a .dropdown-item .router-link-exact-active{
  color: inherit;
}


a .router-link-exact-active{
  color: white !important;
}

</style>
