<template>
  <div class="container it-content" data-spy="scroll" data-target="#navbar" data-offset="0">
    <div class="col-md-8 offset-2 mt-2">
      <h2 class="text-center">
        <img :src="require('../assets/images/onto.png')" width="315" height="70">
        <img :src="require('../assets/images/mappe_di_comunita.png')" width="398" height="70"></h2>
      <h2 class="text-center">
        <a href="http://www.dipinformatica.unito.it/" target="_blank">
          <img :src="require('../assets/images/logo_dip.svg')" width="186" height="70"></a>
        <a href="http://www.dist.polito.it/" target="_blank">
          <img :src="require('../assets/images/dist-full.jpg')" width="433" height="70"></a>
      </h2>
      <p></p>
      <div v-if="$i18n.locale==='it'">
        <h4>Il progetto OnToMap</h4>
        L\applicazione web OnToMap - Mappe di Comunità 3.0 nasce con l\obiettivo di rispondere alle esigenze
        emergenti della cittadinanza, configurandosi come strumento facile e veloce per consultare dati territoriali,
        creare mappe personalizzate, segnalare agli amministratori locali criticità o nuove proposte per rispondere
        alle questioni urbane più vicine agli abitanti.
        <p></p>
        <p>
          OnToMap si basa su una ontologia (formalismo di rappresentazione della conoscenza) che permette
          non solo di consultare dati spaziali e informazioni territoriali -e arricchirli- ma anche di visualizzare
          le relazioni semantiche che intercorrono tra essi.
        </p>
        <p>
          Navigando il grafo dell\ontologia è possibile scoprire come gli elementi che concorrono a definire
          il complesso concetto di territorio si legano e si connettono, articolando nuove prospettive sulla città,
          il territorio, il paesaggio. È inoltre possibile arricchire le sorgenti informative con nuovi dati,
          opportunamente classificati e integrati nelle basi dati di origine.
        </p>
      </div>
      <div v-if="$i18n.locale==='en'">
        <h4>The OnToMap project</h4>
        The web application OnToMap - Mappe di Comunità 3.0 was developed with the aim of responding to
        citizenship emerging needs, being configured as an easy and quick tool to consult spatial data,
        create custom maps, report to local administrators critical issues or new proposals.
        <p></p>
        <p>
          OnToMap is based on a ontology, a knowledge representation formalism, which allows to consult
          spatial data, spatial information and to visualize the semantic relations that exist between them.
          The user interface lets users select the data types of interest on a graph and displays the relevant
          information on a map. Through the graph is possible to identify the constitutive dimensions of the
          territory,
          which take in account the environmental, urban, cultural and social components. Moreover, is
          it possible to enhance the data sources with new data, properly classified and integrated in the source
          database.
        </p>
        <p>
          The ontological approach to the construction of decision-making processes in the context of
          planning represents an emerging methodology, posing as an innovative perspective for expressing
          the complexity of the semantic aspects relative to the territorial representation. This perspective
          allows to enhance the potential given by traditional eParticipation tools (PGIS, PPGIS, VGI),
          stimulating the search for new synergies between heterogeneous technical-scientific skills and
          interoperability between natural and artificial cognitive systems.
        </p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Home"
}
</script>

<style scoped>

</style>
