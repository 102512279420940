import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag)

import router from './router'
//MultiLang
import i18n from './i18n'

Vue.config.productionTip = false


new Vue({
    i18n,
    router,
    render: h => h(App),
}).$mount('#app')
